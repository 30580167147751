@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply text-sm md:text-base
  }
  a {
    @apply text-sm md:text-base
  }
  ul {
    @apply text-sm md:text-base
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272727;
  color: #BFBFBF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 600px){
  li {
    font-size: 0.8rem !important;
  }
  .responsive {
    height: 300px;
  }
}

@media only screen and (min-width: 600px){
  .responsive {
    height: 500px;
  }
}

.custom-underline {
  text-decoration: underline;
  text-underline-position: under;
}

.underline-animation {
  position: relative;
  display: inline-block;
}

.underline-animation:after{
  content: '';
  position: absolute;
  left: 10%;
  transform: translateX(-3%);
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: green;
  transition: width .3s ease-out, opacity .3s ease-out;
}

.underline-animation:hover:after,
.underline-animation.activeClasses:after{
  width: 85%;
  background-color: green;
  opacity: 1
}

.underline-animation.activeClasses:after {
  width: 85%;
  opacity: 1;
}

.underline-animation:not(:hover):after {
  transition: width 0.3s ease-out,
opacity 0.5s ease-out;
  opacity: 0;}

.underline-animation.activeClasses{
  color: green
}

.underline-animation.activeClasses:after{
  background-color: green;
}

/*draw up a square*/
.square {
  height: 100px;
  width: 100px;
  background-color: #555;
  border-radius: 10px;
}

button[role="menuitem"]:active {
  background-color: #4FD890 !important;
}

.absolute {
  background-color: #005B41;
}
